<template>
  <template v-for="(business, index) in businesses" :key="index">
    <business-section
      :business="business"
      :index="index"
      :is-open="isOpen"
      @toggle="toggleTable"
    />

    <properties-section
      :business="business"
      :index="index"
      :is-open="isOpen"
      @toggle="toggleTable"
    />

    <person-associates-section
      :business="business"
      :index="index"
      :is-open="isOpen"
      @toggle="toggleTable"
    />

    <business-associates-section
      :business="business"
      :index="index"
      :is-open="isOpen"
      @toggle="toggleTable"
    />

    <bankruptcy-section
      :business="business"
      :index="index"
      :is-open="isOpen"
      @toggle="toggleTable"
    />

    <company-verification-section
      :business="business"
      :index="index"
      :is-open="isOpen"
      @toggle="toggleTable"
    />

    <experian-section
      :business="business"
      :index="index"
      :is-open="isOpen"
      @toggle="toggleTable"
    />
  </template>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import { computed, reactive, onMounted } from "vue";
import type {
  LexisNexisKybReport,
  Mortgage,
  Owner,
  Party,
  IProperty,
  Seller
} from "@/models/commercialData/lexisnexis/KybReport";
import BusinessSection from "./BusinessSection.vue";
import PropertiesSection from "./PropertiesSection.vue";
import PersonAssociatesSection from "./PersonAssociatesSection.vue";
import BusinessAssociatesSection from "./BusinessAssociatesSection.vue";
import BankruptcySection from "./BankruptcySection.vue";
import CompanyVerificationSection from "./CompanyVerificationSection.vue";
import ExperianSection from "./ExperianSection.vue";

const props = defineProps({
  report: {
    type: Object as PropType<LexisNexisKybReport>,
    required: true
  }
});

const businesses = computed(() => {
  return (
    props.report.TopBusinessReportResponseEx?.response?.Businesses.Business ||
    []
  );
});

const isOpen = reactive<Record<string, boolean>>({
  business: true
});

const toggleTable = (key: string) => {
  isOpen[key] = !isOpen[key];
};

const getOwners = (property: IProperty) => {
  const parties = property.Parties?.Party || [];
  return parties.reduce((acc: Owner[], party: Party) => {
    const owners = party.Owners?.Owner;
    if (owners?.length) {
      return [...acc, ...owners];
    }
    return acc;
  }, []);
};
const getSellers = (property: IProperty) => {
  const parties = property.Parties?.Party || [];
  return parties.reduce((acc: Seller[], party: Party) => {
    const sellers = party.Sellers?.Seller;
    if (sellers?.length) {
      return [...acc, ...sellers];
    }
    return acc;
  }, []);
};
const getMortgages = (property: IProperty) => {
  const parties = property.Parties?.Party || [];
  return parties.reduce((acc: Mortgage[], party: Party) => {
    const mortgages = party.Mortgages?.Mortgage;
    if (mortgages?.length) {
      return [...acc, ...mortgages];
    }
    return acc;
  }, []);
};

onMounted(() => {
  const sections =
    props.report.TopBusinessReportResponseEx?.response?.Businesses?.Business.reduce(
      (acc, curr, index) => {
        const properties =
          curr.PropertySection.PropertyRecords.Properties?.Property || [];
        const nested = properties.reduce((prev, currProperty) => {
          const owners = getOwners(currProperty);
          const sellers = getSellers(currProperty);
          const mortgages = getMortgages(currProperty);
          const ownerKeys = {} as Record<string, boolean>;
          const sellerKeys = {} as Record<string, boolean>;
          const mortgageKeys = {} as Record<string, boolean>;
          owners.forEach((owner, ownerIndex) => {
            ownerKeys[`business_${index}_owners_${ownerIndex}`] = true;
          });
          sellers?.forEach((sellers, sellerIndex) => {
            sellerKeys[`business_${index}_sellers_${sellerIndex}`] = true;
          });
          mortgages.forEach((mortgage, mortgageIndex) => {
            mortgageKeys[`business_${index}_mortgages_${mortgageIndex}`] = true;
          });
          return {
            ...prev,
            ...ownerKeys,
            ...sellerKeys,
            ...mortgageKeys
          };
        }, {});
        return {
          ...acc,
          business: true,
          [`business_${index}_ucc`]: true,
          [`business_${index}_lien`]: true,
          [`business_${index}_ids`]: true,
          [`business_${index}_properties`]: true,
          [`business_${index}_personassociates`]: true,
          [`business_${index}_businessassociates`]: true,
          [`business_${index}_bankruptcy`]: true,
          [`business_${index}_coverification`]: true,
          [`business_${index}_experianreport`]: true,
          [`business_${index}_experianreport_ucc`]: true,
          [`business_${index}_experianreport_lien`]: true,
          [`business_${index}_experianreport_bizid`]: true,
          [`business_${index}_experianreport_bankruptcy`]: true,
          [`business_${index}_experianreport_derog`]: true,
          [`business_${index}_experianreport_judgment`]: true,
          ...nested
        };
      },
      {}
    );
  Object.assign(isOpen, sections);
});
</script>
